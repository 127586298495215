import React from "react";
import { ErrorNotFoundSection } from "../components";

export default function ErrorsPage() {
  return (
    <React.Fragment>
      <div className="erorr-404-page style-5">
        <ErrorNotFoundSection />
      </div>
    </React.Fragment>
  );
}
