/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export function ToTopButton() {
  return (
    <React.Fragment>
      <a href="#" className="to_top">
        <i className="bi bi-chevron-up"></i>
        <small>top</small>
      </a>
    </React.Fragment>
  );
}
