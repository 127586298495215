import React from "react";
import globe from "../../../assets/img/contact_globe.svg";

export const PortfolioStartProjectSection = () => {
  return (
    <React.Fragment>
      {/* <!-- ====== start start-project ====== --> */}
      <section className="download section-padding style-5 bg-light">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="content text-center">
                <div className="section-head text-center style-4">
                  <h2 className="mb-20">
                    {" "}
                    Access your business potentials today &amp; find opportunity
                    for <span> bigger success </span>{" "}
                  </h2>
                </div>
                <div className="butns mt-70">
                  <a
                    href="/pricing-plan"
                    className="btn rounded-pill bg-blue4 fw-bold text-white me-4"
                    target="_blank"
                  >
                    <small> Start A Project Now </small>
                  </a>
                  <a
                    href="/pricing-plan"
                    className="btn rounded-pill hover-blue4 fw-bold border-blue4"
                    target="_blank"
                  >
                    <small> See Pricing &amp; Plan </small>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src={globe} alt="" className="contact_globe" />
      </section>
      {/* <!-- ====== end start-project ====== --> */}
    </React.Fragment>
  );
};
