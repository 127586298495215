import React from "react";

import { Routes, Route, BrowserRouter, Outlet } from "react-router-dom";

import ErrorsPage from "../pages/ErrorsPage";
import HomePage from "../pages/HomePage";
import { FooterSection, TopNavigation, ToTopButton } from "../components";
import ContactPage from "../pages/ContactPage";
import ServicesPage from "../pages/ServicesPage";
import AboutPage from "../pages/AboutPage";
import PortfolioPage from "../pages/PortfolioPage";
import { PricingPage } from "../pages/PricingPage";

const { PUBLIC_URL } = process.env;

export default function AppRoutes() {
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route
          element={
            <>
              <TopNavigation />
              <Outlet />
              <FooterSection />
              <ToTopButton />
            </>
          }
        >
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/pricing-plan" element={<PricingPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/*" element={<ErrorsPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
