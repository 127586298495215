import React from "react";
import man_arrow from "../../../assets/img/choose_us/man_arrow.png";
import icon1 from "../../../assets/img/icons/serv_icons/1.png";
import icon3 from "../../../assets/img/icons/serv_icons/3.png";
import icon5 from "../../../assets/img/icons/serv_icons/5.png";
import bubbles from "../../../assets/img/about/about_s6_bubbles.png";

const AboutServicesSection = () => {
  return (
    <React.Fragment>
      {/* <!-- ====== start services ====== --> */}
      <section className="choose-us style-6">
        <div className="container">
          <div className="row justify-content-between gx-0">
            <div className="col-lg-6">
              <div className="img">
                <img src={man_arrow} alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="info">
                <div className="section-head mb-30 style-5">
                  <h2>
                    Our <span> Services </span>
                  </h2>
                </div>
                <div className="text mb-50 fs-12px color-666">
                  We work closely with our customers to build high quality
                  software solutions with latest and modern technologies.
                </div>
                <ul>
                  <li className="d-flex mb-40">
                    <small className="icon-50 me-4 flex-shrink-0">
                      <img src={icon1} alt="" />
                    </small>
                    <div className="inf">
                      <h5>Free Quote</h5>
                      <p className="fs-12px color-666 mt-2">
                        Trust our top minds to eliminate workflow pain points,
                        implement new technologies & applications.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex mb-40">
                    <small className="icon-50 me-4 flex-shrink-0">
                      <img src={icon3} alt="" />
                    </small>
                    <div className="inf">
                      <h5>Software Design & Development</h5>
                      <p className="fs-12px color-666 mt-2">
                        Develop cost-effective yet high quality websites and
                        mobile apps for your business with latest and modern
                        technologies.
                      </p>
                    </div>
                  </li>
                  <li className="d-flex">
                    <small className="icon-50 me-4 flex-shrink-0">
                      <img src={icon5} alt="" />
                    </small>
                    <div className="inf">
                      <h5>Secure and Compliant</h5>
                      <p className="fs-12px color-666 mt-2">
                        We ensure our systems are built with utmost security
                        systems compliant with GDPR, HIPAA, & PCI.
                      </p>
                    </div>
                  </li>
                </ul>
                <a
                  href="/services"
                  className="btn rounded-pill blue5-3Dbutn hover-blue2 sm-butn fw-bold mt-60 px-5"
                >
                  <span>See More </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <img src={bubbles} alt="" className="bubbles rotate-center" />
        <br />
        <br />
      </section>
      <br />
      <br />
      <br />
      {/* <!-- ====== end services ====== --> */}
    </React.Fragment>
  );
};

export default AboutServicesSection;
