import React from "react";
import ContactSection2 from "../components/sections/ContactSection2";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ContactPage() {
  return (
    <>
      <ToastContainer />
      <ContactSection2 />
    </>
  );
}
