/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import team_shape from "../../../assets/img/team/team_shap.png";
import { Link } from "react-router-dom";

export default function TeamSection() {
  return (
    <React.Fragment>
      {/* <!-- ====== start team ====== --> */}
      <section className="team section-padding pt-0 style-1" id="team">
        <div className="container">
          <div className="section-head mb-60 text-center">
            <br />
            <h6 className="color-main text-uppercase wow fadeInUp">
              meet our executive team
            </h6>
            <h2 className="wow fadeInUp">
              Clients Satisfaction,
              <span className="fw-normal">Our Reputation</span>
            </h2>
          </div>
          <div className="content" style={{ justifyContent: "center" }}>
            <div className="team_box wow fadeInUp" data-wow-delay="0">
              <div className="avatar">
                <img
                  src="https://ik.imagekit.io/nebham/nebham/hkdahal_RexezcQU6.HEIC?updatedAt=1694730024448"
                  alt=""
                />
              </div>
              <div className="info">
                <h6 className="color-main">HK</h6>
                <small>CEO, Co-Founder</small>
              </div>
            </div>
            <div className="team_box wow fadeInUp" data-wow-delay="0.2s">
              <div className="avatar">
                <img
                  src="https://ik.imagekit.io/nebham/nebham/gagan_f2dI08z2q.jpeg?updatedAt=1694730190486"
                  alt=""
                />
              </div>
              <div className="info">
                <h6 className="color-main">Gagan</h6>
                <small>Co-Founder</small>
              </div>
            </div>
            <div className="team_box wow fadeInUp" data-wow-delay="0.4s">
              <div className="avatar">
                <img
                  src="https://ik.imagekit.io/nebham/nebham/krishna_dahal_Q1-3ji207E.png?updatedAt=1694730359271"
                  alt=""
                />
              </div>
              <div className="info">
                <h6 className="color-main">Krishna</h6>
                <small>Co-Founder</small>
              </div>
            </div>
          </div>
          <div className="bttns mt-4 text-center">
            <Link to="/about" className="btn btn-dark wow zoomIn">
              <span>See All Team</span>
            </Link>
          </div>
        </div>
        <img src={team_shape} alt="" className="team_shap" />
      </section>
      {/* <!-- ====== end team ====== --> */}
    </React.Fragment>
  );
}
