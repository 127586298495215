import React from "react";

import { PortfolioStartProjectSection, PortfolioSection } from "../components";

export default function PortfolioPage() {
  return (
    <div className="portfolio-page style-1">
      <PortfolioSection />
      <PortfolioStartProjectSection />
    </div>
  );
}
