import React from "react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/bundle";

import AppRoutes from "./routing/AppRoutes";

function App() {
  return <AppRoutes />;
}

export default App;
