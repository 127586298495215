/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import services_shape_l from "../../../assets/img/services/ser_shap_l.png";
import services_shape_r from "../../../assets/img/services/ser_shap_r.png";
import services_icon1 from "../../../assets/img/icons/serv_icons/1.png";
import services_icon2 from "../../../assets/img/icons/serv_icons/2.png";
import services_icon3 from "../../../assets/img/icons/serv_icons/3.png";
import services_icon4 from "../../../assets/img/icons/serv_icons/4.png";
import services_icon5 from "../../../assets/img/icons/serv_icons/5.png";

export class ServicesSection extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <!-- ====== start services ====== --> */}
        <section
          className="services section-padding style-1"
          data-scroll-index="2"
          id="services"
        >
          <div className="container">
            <div className="row">
              <div className="col offset-lg-1">
                <div className="section-head mb-60">
                  <h6 className="color-main text-uppercase wow fadeInUp">
                    our services
                  </h6>
                  <h2 className="wow fadeInUp">
                    Perfect IT Solutions
                    <br />
                    <span className="fw-normal">For Your Business</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="row">
                <div className="col-lg-4">
                  <div
                    className="service-box mb-4 wow fadeInUp"
                    data-wow-delay="0"
                  >
                    <h5>
                      <a href="page-services-5.html"> Free Consultation </a>
                      <span className="num">01</span>
                    </h5>
                    <div className="icon">
                      <img src={services_icon1} alt="" />
                    </div>
                    <div className="info">
                      <div className="text">
                        Trust our top minds to eliminate workflow pain points,
                        implement new technologies & applications.
                      </div>
                      <div className="tags">
                        <a href="#">Free Quote</a>&nbsp;
                        <a href="#">Consultation</a>&nbsp;
                        <a href="#">Digital Management</a>&nbsp;
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div
                    className="service-box mb-4 wow fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    <h5>
                      <a href="page-services-5.html"> Website Development </a>
                      <span className="num">02</span>
                    </h5>
                    <div className="icon">
                      <img src={services_icon3} alt="" />
                    </div>
                    <div className="info">
                      <div className="text">
                        We develop cost-effective yet high quality websites for
                        your business with latest and modern technologies.
                      </div>
                      <div className="tags">
                        <a href="#">Restaurant</a>&nbsp;
                        <a href="#">Non-Profit</a>&nbsp;
                        <a href="#">Jewelry Store</a>&nbsp;
                        <a href="#">Custom Website</a>&nbsp;
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div
                    className="service-box mb-4 wow fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    <h5>
                      <a href="page-services-5.html"> App Development </a>
                      <span className="num">03</span>
                    </h5>
                    <div className="icon">
                      <img src={services_icon3} alt="" />
                    </div>
                    <div className="info">
                      <div className="text">
                        For any iOS and Android app ideas or businesses, we
                        provide cost-effective and high quality apps.
                      </div>
                      <div className="tags">
                        <a href="#">iOS & Android</a>&nbsp;
                        <a href="#">Jewelry</a>&nbsp;
                        <a href="#">E-Commerce</a>&nbsp;
                        <a href="#">Custom Apps</a>&nbsp;
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div
                    className="service-box mb-4 mb-md-0 wow fadeInUp"
                    data-wow-delay="0"
                  >
                    <h5>
                      <a href="page-services-5.html"> Graphic Design </a>
                      <span className="num">04</span>
                    </h5>
                    <div className="icon">
                      <img src={services_icon4} alt="" />
                    </div>
                    <div className="info">
                      <div className="text">
                        Our experts in graphic design know how to communicate
                        well with your customers via power of graphic designs.
                      </div>
                      <div className="tags">
                        <a href="#">Flyers</a>&nbsp;
                        <a href="#">Brochures</a>&nbsp;
                        <a href="#">Digital Ads</a>&nbsp;
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="service-box mb-4 mb-md-0 wow fadeInUp"
                    data-wow-delay="0.2s"
                  >
                    <h5>
                      <a href="page-services-5.html"> Cloud Services </a>
                      <span className="num">05</span>
                    </h5>
                    <div className="icon">
                      <img src={services_icon5} alt="" />
                    </div>
                    <div className="info">
                      <div className="text">
                        Trust our top minds to eliminate workflow pain points,
                        implement new tech & app.
                      </div>
                      <div className="tags">
                        <a href="#">Cloud Storage</a>&nbsp;
                        <a href="#">Hosting</a>&nbsp;
                        <a href="#">Email Solutions</a>&nbsp;
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className="service-box wow fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    <h5>
                      <a href="page-services-5.html"> Secure Systems </a>
                      <span className="num">06</span>
                    </h5>
                    <div className="icon">
                      <img src={services_icon2} alt="" />
                    </div>
                    <div className="info">
                      <div className="text">
                        We ensure our systems are built with utmost security
                        systems compliant with GDPR, HIPAA, & PCI.
                      </div>
                      <div className="tags">
                        <a href="#">HIPAA</a>&nbsp;
                        <a href="#">GDPR</a>&nbsp;
                        <a href="#">Payment</a>&nbsp;
                        <a href="#">SSL</a>&nbsp;
                        <a href="#">Encryption</a>&nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src={services_shape_l} alt="" className="ser_shap_l" />
          <img src={services_shape_r} alt="" className="ser_shap_r" />
        </section>
        {/* <!-- ====== end services ====== --> */}
      </React.Fragment>
    );
  }
}

export default ServicesSection;
