import React from "react";

import about_bg from "../../../assets/img/about/num_shap.png";
import { Link } from "react-router-dom";

export function AboutSection() {
  return (
    <section className="about style-1" data-scroll-index="1" id="about">
      <div className="container">
        <div className="content">
          {/*<div className="about-logos d-flex align-items-center justify-content-between border-bottom border-1 brd-light pb-20">*/}
          {/*  <a href="#" className="logo wow fadeInUp" data-wow-delay="0">*/}
          {/*    <img src={logo1} alt="" />*/}
          {/*  </a>*/}
          {/*  <a href="#" className="logo wow fadeInUp" data-wow-delay="0.2s">*/}
          {/*    <img src={logo2} alt="" />*/}
          {/*  </a>*/}
          {/*  <a href="#" className="logo wow fadeInUp" data-wow-delay="0.4s">*/}
          {/*    <img src={logo3} alt="" />*/}
          {/*  </a>*/}
          {/*  <a href="#" className="logo wow fadeInUp" data-wow-delay="0.6s">*/}
          {/*    <img src={logo4} alt="" />*/}
          {/*  </a>*/}
          {/*  <a href="#" className="logo wow fadeInUp" data-wow-delay="0.8s">*/}
          {/*    <img src={logo5} alt="" />*/}
          {/*  </a>*/}
          {/*</div>*/}
          <div className="about-info">
            <div className="row justify-content-between">
              <div className="col-lg-5">
                <div className="title">
                  <h3 className="wow fadeInUp slow">
                    “We aim to promote tech literacy by empowering all.”
                  </h3>
                  <small className="wow fadeInUp slow fw-bold">
                    NEBHAM LLC
                  </small>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="info">
                  <h6 className="wow fadeInUp slow">
                    Change starts from within, and it fosters by coming
                    together. We aim to promote tech literacy by building simple
                    to complex software solutions.
                  </h6>
                  <p className="wow fadeInUp slow">
                    Nebham LLC is the partner of choice for many of our
                    community’s leading enterprises, businesses, and non-profit
                    organizations. We help businesses elevate their value
                    through custom software development, product design, QA and
                    consultancy services.
                  </p>
                  <Link
                    to="/services"
                    className="btn btn-outline-light mt-5 sm-butn wow fadeInUp slow"
                  >
                    <span>See our services</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="about-numbers">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="num-item wow fadeInUp" data-wow-delay="0">
                  <div className="num">
                    <span className="counter"> 10 </span>
                    <span>
                      <i className="fas fa-plus"></i>
                    </span>
                  </div>
                  <div className="inf">Years of Experience</div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="num-item wow fadeInUp" data-wow-delay="0.2s">
                  <div className="num">
                    <span className="counter"> $1.5 </span>
                    <span> M+ </span>
                  </div>
                  <div className="inf">Payment Processed</div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="num-item wow fadeInUp" data-wow-delay="0.4s">
                  <div className="num">
                    <span className="counter"> 10+ </span>
                  </div>
                  <div className="inf">Apps Live</div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="num-item wow fadeInUp" data-wow-delay="0.6s">
                  <div className="num">
                    <span className="counter"> 100K+ </span>
                  </div>
                  <div className="inf">Satisfied Users</div>
                </div>
              </div>
            </div>
          </div>
          <img src={about_bg} alt="" className="about_shap" />
        </div>
      </div>
    </section>
  );
}
