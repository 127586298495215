import React, { Component } from "react";
import head_img from "../../../assets/img/header/head.png";
import head_bg_l from "../../../assets/img/header/head_shape_l.png";
import head_bg_r from "../../../assets/img/header/head_shape_r.png";
import { SmallTag } from "../ui";
import { Link } from "react-router-dom";

export class HomeHeaderSection extends Component {
  render() {
    return (
      <React.Fragment>
        <header className="section-padding style-1" data-scroll-index="0">
          <div className="container">
            <div className="content">
              <div className="row">
                <div className="col-lg-7">
                  <div className="info">
                    <div className="section-head mb-60">
                      <h6 className="color-main text-uppercase">
                        With Nebham LLC
                      </h6>
                      <h2>
                        Build all Software Solutions{" "}
                        <span className="fw-normal">with High Quality</span>
                      </h2>
                      <div className="tags">
                        <SmallTag title="Cost-Effective" />
                        <SmallTag title="Deliver on Time" />
                      </div>
                    </div>
                    <div className="text">
                      With NEBHAM, we aim to build high quality software
                      solutions with cost-effective and modern technologies and
                      deliver timely.
                    </div>
                    <div className="bttns mt-5">
                      <Link to="/services" className="btn btn-dark">
                        <span>our services</span>
                      </Link>
                      <a
                        href="https://www.youtube.com/watch?v=9hVDAlK-3SI"
                        data-lity
                        className="vid-btn"
                      >
                        <i className="bi bi-play wow heartBeat infinite slow"></i>
                        <span>
                          Nebham’s <br />
                          Intro
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 offset-lg-1">
                  <div className="img">
                    <img src={head_img} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src={head_bg_r} alt="" className="head-shape-r wow" />
          <img src={head_bg_l} alt="" className="head-shape-l wow" />
        </header>
      </React.Fragment>
    );
  }
}
