import React from "react";

import TeamSection from "../components/sections/TeamSection";
import { AboutSection, AboutHeaderSection } from "../components";
import AboutReasonSection from "../components/sections/AboutReasonSection";
import AboutPhilosophySection from "../components/sections/AboutPhilosophySection";
import AboutServicesSection from "../components/sections/AboutServicesSection";

export default function AboutPage() {
  return (
    <>
      <br />
      <br />
      <div className="about-page style-5">
        <AboutHeaderSection />
        <AboutSection />
        <AboutReasonSection />
        <AboutPhilosophySection />
        <AboutServicesSection />
        <TeamSection />
        {/* <AboutTeamSection /> */}
      </div>
    </>
  );
}
