import React from "react";
import { PricingPlanSection, PricingQuotationSection } from "../components";

export const PricingPage = () => {
  return (
    <React.Fragment>
      <PricingPlanSection />
      <PricingQuotationSection />
    </React.Fragment>
  );
};
