/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Component } from "react";

import { Link } from "react-router-dom";

import footer_bg_l from "../../../assets/img/footer/foot_l.png";
import footer_bg_r from "../../../assets/img/footer/foot_r.png";
import logo_white from "../../../assets/img/NebhamLogos/white_logo.png";
import { ToTopButton } from "../ui";

export class FooterSection extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <!-- ====== start footer ====== --> */}
        <footer className="style-1">
          <div className="container">
            <div className="content">
              <div className="row justify-content-center">
                <div className="col-lg-4">
                  <div className="foot_info">
                    <a href="#" className="logo mb-3 w-50">
                      <img src={logo_white} alt="" />
                    </a>
                    <div className="text mb-4">
                      Building a community, not company
                    </div>
                    <ul className="mb-4 p-0">
                      <li className="d-flex">
                        <i className="bi bi-house me-3"></i>
                        <a href="#">
                          <span>Cleveland, OH</span>
                        </a>
                      </li>
                      <li className="d-flex">
                        <i className="bi bi-envelope me-3"></i>
                        <a href="mailto:contact@nebham.com?subject=Message through Website">
                          <span>contact@nebham.com</span>
                        </a>
                      </li>
                      <li className="d-flex">
                        <i className="bi bi-phone me-3"></i>
                        <a href="tel:+1216-505-0679">
                          <span>+1 216-505-0679</span>
                        </a>
                      </li>
                    </ul>
                    <div className="social_icons">
                      <a href="https://www.facebook.com/nebhamsoft">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="https://www.instagram.com/nebhamllc">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="https://github.com/nebham">
                        <i className="fab fa-github"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="links">
                    <div className="cont">
                      <h6 className="link_title">Services</h6>
                      <ul className="p-0">
                        <li>
                          <a href="#">IT Consultations </a>
                        </li>
                        <li>
                          <a href="#">Website Development </a>
                        </li>
                        <li>
                          <a href="#">Mobile Apps Development </a>
                        </li>
                        <li>
                          <a href="#">UI/UX Design </a>
                        </li>
                        <li>
                          <a href="#">Cloud Services </a>
                        </li>
                        <li>
                          <a href="#">Custom Software </a>
                        </li>
                        <li>
                          <a href="#">Graphic Design </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="links">
                    <div className="cont">
                      <h6 className="link_title">Information</h6>
                      <ul className="p-0">
                        <li>
                          <Link to="/about">About Nebham </Link>
                        </li>
                        <li>
                          <a href="#">Contact </a>
                        </li>
                        <li>
                          <Link to="/services">Services </Link>
                        </li>
                        <li>
                          <a href="/pricing-plan">Pricing Plan </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="foot_subscribe">
                    <h6 className="link_title">Subscribe to Follow-up</h6>
                    <p>
                      Leave your email address for follow-up from our marketing
                      team.
                    </p>
                    <div className="input-group my-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your email"
                        aria-label="Enter your email"
                        aria-describedby="button-addon2"
                      />
                      <button
                        className={`btn butn-gard border-0 text-white px-3`}
                        type="button"
                        id="button-addon2"
                      >
                        <span>Subscribe</span>
                      </button>
                    </div>
                    <p className="fst-italic">
                      By subscribing, you accepted the our&nbsp;
                      <a href="#" className="text-decoration-underline">
                        Terms and Conditions.
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="foot">
                  <p>
                    © 2023 Copyright{" "}
                    <span className="text-white">NEBHAM LLC</span>. All rights
                    reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img src={footer_bg_l} alt="" className="foot_l" />
          <img src={footer_bg_r} alt="" className="foot_r" />
        </footer>
        {/* <!-- ====== end footer ====== --> */}

        <ToTopButton />
      </React.Fragment>
    );
  }
}
