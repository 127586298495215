import React from "react";

import choose_lines from "../../../assets/img/choose_us/choose_lines.svg";
import choose_brands from "../../../assets/img/choose_us/choose_brands.png";
import choose_bubbles from "../../../assets/img/choose_us/choose_bubbles.png";
import { Link } from "react-router-dom";

export function ChooseUsSection() {
  return (
    <React.Fragment>
      {/* <!-- ====== start choose-us====== --> */}
      <section
        className="choose-us section-padding pt-0 style-1"
        data-scroll-index="3"
      >
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-5">
              <div className="info">
                <div className="section-head mb-60">
                  <h6 className="color-main text-uppercase wow fadeInUp">
                    Our work process
                  </h6>
                  <h2 className="wow fadeInUp">
                    Discover New Ideas With Us
                    <span className="fw-normal">
                      Imagination can take us everywhere
                    </span>
                  </h2>
                </div>
                <div className="text">
                  If you can imagine, it is worth exploring. Everything is
                  getting online, turn your idea into a product with us.
                </div>
                <ul>
                  <li className="wow fadeInUp">
                    <span className="icon">
                      <i className="bi bi-check2"></i>
                    </span>
                    <h6>We will hear your idea, and develop a prototype.</h6>
                  </li>
                  <li className="wow fadeInUp">
                    <span className="icon">
                      <i className="bi bi-check2"></i>
                    </span>
                    <h6>Iteratively design and develop the product.</h6>
                  </li>
                  <li className="wow fadeInUp">
                    <span className="icon">
                      <i className="bi bi-check2"></i>
                    </span>
                    <h6>
                      Explore additional ideas, and keep on turning your ideas
                      into products.
                    </h6>
                  </li>
                  <li className="wow fadeInUp">
                    <span className="icon">
                      <i className="bi bi-check2"></i>
                    </span>
                    <h6>Dedicated Support 24/7</h6>
                  </li>
                </ul>

                <Link
                  to="/pricing-plan"
                  className="btn butn-gard border-0 text-white wow fadeInUp"
                >
                  <span>Free Quote</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <img src={choose_lines} alt="" className="choose-us-img" />
        <img src={choose_brands} alt="" className="choose-us-brands" />
        <img src={choose_bubbles} alt="" className="choose-us-bubbles" />
      </section>
      {/* <!-- ====== end choose-us====== --> */}
    </React.Fragment>
  );
}
