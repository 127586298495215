import React from "react";
import vid_banner from "../../../assets/img/vid_banner5.png";
import head_line from "../../../assets/img/header/head5_line.png";
import head_pen from "../../../assets/img/header/head5_pen.png";
import hand_megaphone from "../../../assets/img/header/hand_megaphone.png";
import hand_rating from "../../../assets/img/header/head6_rating.png";
import linechart from "../../../assets/img/header/header5_linechart.png";
import rocket from "../../../assets/img/header/rocket.png";

export const AboutHeaderSection = () => {
  return (
    <React.Fragment>
      {/* <!-- ====== start about ====== --> */}
      <header className="about-page-sec style-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="info">
                <h1>
                  We provide High Quality <br />
                  IT Solutions & Technology for any
                  <span>
                    Business
                    <img src={head_line} alt="" className="head-line" />
                    <img src={head_pen} alt="" className="head-pen" />
                  </span>
                </h1>
                <p>
                  Nebham helps you automate, digitize, and future-proof your
                  business by building high quality solutions
                  <br />
                  in a timely manner within your budget.
                </p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="main-vid">
                <img src={vid_banner} alt="" />
                <a
                  href="https://www.youtube.com/watch?v=9hVDAlK-3SI"
                  data-lity
                  className="play-icon"
                >
                  <i className="fas fa-play"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <img src={hand_megaphone} alt="" className="hand-mega slide_up_down" />
        <img src={hand_rating} alt="" className="head6-rating scale_up_down" />
        <img src={linechart} alt="" className="head6-charts scale_up_down" />
        <img src={rocket} alt="" className="head6-rocket" />
      </header>
      {/* <!-- ====== end about ====== --> */}
    </React.Fragment>
  );
};
