/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import barnamala from "../../../assets/img/projects/barnamala.png";
import brave from "../../../assets/img/projects/brave.png";
import karyagram from "../../../assets/img/projects/karyagram.png";
import intrafoundation from "../../../assets/img/projects/intrafoundation.png";
import portfolio_shape_l from "../../../assets/img/projects/prog/shap_l.png";
import portfolio_shape_r from "../../../assets/img/projects/prog/shap_r.png";

export function PortfolioSection() {
  return (
    <React.Fragment>
      {/* <!-- ====== start portfolio ====== --> */}
      <section
        className="portfolio section-padding bg-gray style-1"
        data-scroll-index="4"
        id="portfolio"
      >
        <div className="container">
          <div className="row">
            <div className="col offset-lg-1">
              <div className="section-head mb-60">
                <h6 className="color-main text-uppercase wow fadeInUp">
                  Portfolio
                </h6>
                <h2 className="wow fadeInUp">
                  Latest Projects{" "}
                  <span className="fw-normal">From Our Team</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="content wow fadeIn slow">
            <div className="portfolio-slider">
              <div className="swiper-container">
                <div className="swiper-wrapper">
                  <div className="swiper-slide w-25 m-2">
                    <div className="portfolio-card">
                      <div className="img">
                        <img src={barnamala} alt="" />
                      </div>
                      <div className="info">
                        <h5>
                          <a href="page-single-project-5.html">
                            Saral Barnamala
                          </a>
                        </h5>
                        <small className="d-block color-main text-uppercase">
                          <a href="#">Mobile App</a>
                        </small>
                        <div className="text">
                          For your kids learning. Attractive and interactive.
                        </div>
                        <div className="tags">
                          <a href="#">Pro Bono</a>&nbsp;
                          <a href="#">Learning</a>&nbsp;
                          <a href="#">Audio</a>&nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide w-25 m-2">
                    <div className="portfolio-card">
                      <div className="img">
                        <img src={brave} alt="" />
                      </div>
                      <div className="info">
                        <h5>
                          <a href="page-single-project-5.html">BRAVE</a>
                        </h5>
                        <small className="d-block color-main text-uppercase">
                          <a href="#">Non-Profit,</a>&nbsp;
                          <a href="#">Dispatch</a>&nbsp;
                        </small>
                        <div className="text">
                          A custom app built for a Non-Profit to manage their
                          project during pandemic.
                        </div>
                        <div className="tags">
                          <a href="#">iOS & Android</a>&nbsp;
                          <a href="#">Volunteer</a>&nbsp;
                          <a href="#">RBAC</a>&nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide w-25 m-2">
                    <div className="portfolio-card">
                      <div className="img">
                        <img src={intrafoundation} alt="" />
                      </div>
                      <div className="info">
                        <h5>
                          <a href="page-single-project-5.html">
                            Intra Foundation
                          </a>
                        </h5>
                        <small className="d-block color-main text-uppercase">
                          <a href="#">Reality Shows</a>&nbsp;
                        </small>
                        <div className="text">
                          Developed Reality Shows voting app along with other
                          non-profit management.
                        </div>
                        <div className="tags">
                          <a href="#">iOS & Android</a>&nbsp;
                          <a href="#">Reality Shows</a>&nbsp;
                          <a href="#">Payment</a>&nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide w-25 m-2">
                    <div className="portfolio-card">
                      <div className="img">
                        <img src={karyagram} alt="" />
                      </div>
                      <div className="info">
                        <h5>
                          <a href="page-single-project-5.html">KaryaGram</a>
                        </h5>
                        <small className="d-block color-main text-uppercase">
                          <a href="#">Custom App,</a>&nbsp;
                          <a href="#">Event/Tickets</a>&nbsp;
                        </small>
                        <div className="text">
                          An event management and ticketing system app.
                        </div>
                        <div className="tags">
                          <a href="#">Event</a>&nbsp;
                          <a href="#">Ticket</a>&nbsp;
                          <a href="#">QR</a>
                          <a href="#">Payment</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide w-25 m-2">
                    <div className="portfolio-card">
                      <div className="img">
                        <img src={karyagram} alt="" />
                      </div>
                      <div className="info">
                        <h5>
                          <a href="page-single-project-5.html">KaryaGram</a>
                        </h5>
                        <small className="d-block color-main text-uppercase">
                          <a href="#">Website Development,</a>
                          <a href="#">UI/UX Design</a>
                        </small>
                        <div className="text">
                          Trust our top minds to eliminate workflow pain points,
                          implement new tech & app.
                        </div>
                        <div className="tags">
                          <a href="#">WordPress</a>&nbsp;
                          <a href="#">PHP</a>&nbsp;
                          <a href="#">HTML/CSS</a>&nbsp;
                          <a href="#">Figma</a>&nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- ====== slider pagination ====== --> */}
              {/* <div className="swiper-pagination"></div> */}

              {/* <!-- ====== slider navigation ====== --> */}
              {/* <div className="swiper-button-next"></div>
              <div className="swiper-button-prev"></div> */}
            </div>
          </div>
        </div>
        <img src={portfolio_shape_r} alt="" className="shap_r" />
        <img src={portfolio_shape_l} alt="" className="shap_l" />
      </section>
      {/* <!-- ====== end portfolio ====== --> */}
    </React.Fragment>
  );
}
