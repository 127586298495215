/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import superman3D from "../../../assets/img/about/superman_3d.png";
import bubbles from "../../../assets/img/about/about_s6_bubbles.png";

const AboutPhilosophySection = () => {
  return (
    <React.Fragment>
      {/* <!-- ====== start about ====== --> */}
      <section className="about section-padding style-5 style-6">
        <div className="content border-0 p-0">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-4 order-2 order-lg-0">
                <div className="section-head mb-30 style-5">
                  <h2>
                    Nebham’s <span> Philosophy </span>
                  </h2>
                </div>
                <p>
                  We aim to promote tech literacy by building accessible
                  software solutions for all the businesses from small to large.
                </p>
                <div className="line-links">
                  <a href="#">Go-to Company for Software needs</a>
                  <a href="#">High Quality within given Budget</a>
                  <a href="#">Technical Solutions for all Business Needs</a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="img">
                  <img src={superman3D} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src={bubbles} alt="" className="bubbles rotate-center" />
      </section>
      {/* <!-- ====== end about ====== --> */}
    </React.Fragment>
  );
};

export default AboutPhilosophySection;
