import React from "react";
import { ServicesSection } from "../components";

export default function ServicesPage() {
  return (
    <>
      <ServicesSection />
    </>
  );
}
