import React from "react";
import lines from "../../../assets/img/testimonials/testi3_lines.png";
export const PricingPlanSection = () => {
  return (
    <React.Fragment>
      <section className="pricing style-3">
        <div className="container">
          <div className="content section-padding">
            <div className="row">
              <div className="col-lg-4">
                <div className="info">
                  <div className="section-head style-3 mb-40">
                    <h3>
                      {" "}
                      Affordable <span> Price </span>
                    </h3>
                  </div>
                  <p className="text text-gray mb-30">
                    Kick-start your project with our pricing plan. We handle all
                    the practical aspects related to building your project.
                  </p>
                  <a
                    href="/services"
                    className="btn rounded-pill border-blue2 hover-blue2 mt-60 sm-butn"
                  >
                    <span>Our Services</span>
                  </a>
                </div>
              </div>
              <div className="col-lg-7 offset-lg-1">
                <div className="price-cards">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="pricing-card style-3 mb-30 mb-lg-0">
                        <div className="card-head">
                          <div className="title">
                            <h4>Basic Project</h4>
                            <small>Basic features</small>
                          </div>
                          <div className="price">
                            <h5>$25</h5>
                            <small>per month</small>
                          </div>
                        </div>
                        <div className="card-body">
                          <ul>
                            <li>
                              <i className="bi bi-check"></i>
                              <small>Limited Revisions</small>
                            </li>
                            <li>
                              <i className="bi bi-check"></i>
                              <small>Maintenance Support</small>
                            </li>
                            <li>
                              <i className="bi bi-check"></i>
                              <small>FREE Domain</small>
                            </li>
                          </ul>
                        </div>
                        <a
                          href="/contact"
                          className="btn rounded-pill bg-blue2 sm-butn w-100 text-white"
                        >
                          <span>Get Started Now</span>
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="pricing-card dark-card style-3">
                        <div className="card-head">
                          <div className="title">
                            <h4>
                              Premium <small>Recommended</small>
                            </h4>
                            <small>Premium features</small>
                          </div>
                          <div className="price">
                            <h5>$30</h5>
                            <small>per month</small>
                          </div>
                        </div>
                        <div className="card-body">
                          <ul>
                            <li>
                              <i className="bi bi-check"></i>
                              <small>Unlimited Revisions</small>
                            </li>
                            <li>
                              <i className="bi bi-check"></i>
                              <small>Maintenance Support</small>
                            </li>
                            <li>
                              <i className="bi bi-check"></i>
                              <small>FREE Domain</small>
                            </li>
                            <li>
                              <i className="bi bi-check"></i>
                              <small>
                                Dashboard access for Content Management
                              </small>
                            </li>
                            <li>
                              <i className="bi bi-check"></i>
                              <small>Email Solutions</small>
                            </li>
                          </ul>
                        </div>
                        <a
                          href="/contact"
                          className="btn rounded-pill bg-blue2 sm-butn w-100 text-white"
                        >
                          <span>Get Started Now</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img src={lines} alt="" className="testi_lines w-100" />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
