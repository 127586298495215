import React from "react";
import code_3d from "../../../assets/img/icons/code3d.png";
import price_3d from "../../../assets/img/icons/price3d.png";
import message_3d from "../../../assets/img/icons/message3d.png";

const AboutReasonSection = () => {
  return (
    <React.Fragment>
      {/* <!-- ====== start Reasons ====== --> */}
      <section className="community pt-40 style-5">
        <div className="container">
          <div className="section-head text-center mb-40 style-5">
            <h2 className="mb-20">
              Top <span> Reasons </span>
            </h2>
            <p>Build High Quality Software with Modern Technologies</p>
          </div>
          <div className="content rounded-pill">
            <div className="commun-card">
              <div className="icon">
                <img src={code_3d} alt="" />
              </div>
              <div className="inf">
                <h5>Modern Technologies</h5>
              </div>
            </div>
            <div className="commun-card">
              <div className="icon">
                <img src={price_3d} alt="" />
              </div>
              <div className="inf">
                <h5>Affordable Cost</h5>
              </div>
            </div>
            <div className="commun-card">
              <div className="icon">
                <img src={message_3d} alt="" />
              </div>
              <div className="inf">
                <h5>Meet Customers' Goals</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====== end Reasons ====== --> */}
    </React.Fragment>
  );
};

export default AboutReasonSection;
