import React from "react";

type Props = {
  title: string;
};

export function SmallTag({ title }: Props) {
  return (
    <span
      className="color-main"
      style={{
        backgroundColor: "#eef4f8",
        marginRight: 5,
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: 4,
      }}
    >
      {title}
    </span>
  );
}
