import React from "react";

import {
  AboutSection,
  ChooseUsSection,
  HomeHeaderSection,
  PortfolioSection,
  ServicesSection,
} from "../components";
import TeamSection from "../components/sections/TeamSection";
export default function HomePage() {
  return (
    <>
      <HomeHeaderSection />
      <AboutSection />
      <ServicesSection />
      <ChooseUsSection />
      <PortfolioSection />
      {/*<TestimoialsSection />*/}
      <TeamSection />
    </>
  );
}
