/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export const PricingQuotationSection = () => {
  return (
    <React.Fragment>
      {/* <!-- ====== start contact page ====== --> */}
      <section className="contact section-padding pt-50 style-6">
        <div className="container">
          <div className="section-head text-center mb-100 style-5">
            <h2 className="mb-20">
              Need a <span> Tailored Plan? </span>
            </h2>
            <p>
              Get in touch below and we will contact you again after we receive
              your request in 24h
            </p>
          </div>
          <div className="text-center mb-100">
            <h2 className="ltspc-20 text-uppercase fs-1 lh-1 mb-50 mt-30 color-blue5">
              <a href="tel:+1216-505-0679">(+1) 216-505-0679</a>
            </h2>
            <h4 className="fw-normal mb-20 color-000">
              <a href="mailto:contact@nebham.com?subject=Quote Request">
                contact@nebham.com
              </a>
            </h4>
            <h4 className="fw-normal mb-10 color-000">Cleveland, OH USA</h4>
          </div>
          <div className="content">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <form action="contact.php" className="form" method="post">
                  <p className="text-center text-danger fs-12px mb-30">
                    The field is required mark as *
                  </p>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mb-20">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-20">
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          placeholder="Email Address *"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-20">
                        <input
                          type="text"
                          name="phone"
                          className="form-control"
                          placeholder="Phone Number (option)"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-20">
                        <input
                          type="text"
                          name="website"
                          className="form-control"
                          placeholder="Your Website (option)"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-20">
                        <select name="option" className="form-select">
                          <option value="how can we help" selected>
                            How can we help you?
                          </option>
                          <option value="option 1">option 1</option>
                          <option value="option 2">option 2</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          className="form-control"
                          placeholder="How can we help you?"
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-12 text-center">
                      <div className="form-check d-inline-flex mt-30 mb-30">
                        <input
                          className="form-check-input me-2 mt-0"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label className="form-check-label small">
                          By submitting, i’m agreed to the
                          <a href="#" className="text-decoration-underline">
                            Terms & Conditions
                          </a>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-12 text-center">
                      <input
                        type="submit"
                        value="Send Your Request"
                        className="btn rounded-pill blue5-3Dbutn hover-blue2 sm-butn fw-bold text-light"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <img
              src="assets/img/icons/contact_a.png"
              alt=""
              className="contact_a"
            />
            <img
              src="assets/img/icons/contact_message.png"
              alt=""
              className="contact_message"
            />
          </div>
        </div>
      </section>
      {/* <!-- ====== end contact page ====== --> */}
    </React.Fragment>
  );
};
