import React from "react";
import { Link, useLocation } from "react-router-dom";

type Props = {
  title: string;
  to: string;
};

export function TopNavLink({ title, to }: Props) {
  const location = useLocation();

  const isActive = () => location.pathname === to;

  return (
    <li className="nav-item fs-12px">
      <Link className={`nav-link ${isActive() ? "active" : ""}`} to={to}>
        &nbsp; {title}&nbsp;
      </Link>
    </li>
  );
}
