import React from "react";
import { Link } from "react-router-dom";

import logo_blue from "../../../assets/img/NebhamLogos/blue_logo.png";
import { TopNavLink } from "./TopNavLink";

export function TopNavigation() {
  return (
    <React.Fragment>
      <div className="top-navbar style-1">
        <div className="container">
          <div className="content">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div className="top-links">
                  <div className="text text-white">
                    <i className="fas fa-bullhorn"></i>
                    <strong>Now Hiring:</strong>
                    <span>
                      Are you a driven and motivated &nbsp;
                      <Link to="/contact" className="p-0 text-white">
                        <u>Software Engineer?</u>
                      </Link>
                      {" Contact Today"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="r-side">
                  <div className="socail-icons">
                    <a href="https://www.facebook.com/nebhamsoft">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="https://www.instagram.com/nebhamllc">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="https://github.com/nebham">
                      <i className="fab fa-github"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg navbar-light style-1 sticky-top bg-white shadow">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src={logo_blue} alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav text-center d-flex justify-content-around w-60 me-auto mb-2 mb-lg-0">
              <TopNavLink title="home" to="/" />
              <TopNavLink title="about" to="/about" />
              <TopNavLink title="services" to="/services" />
              <TopNavLink title="portfolio" to="/portfolio" />
              <TopNavLink title="contact" to="/contact" />
            </ul>
            <div className="nav-side">
              <div className="hotline pe-4">
                <a href="tel:+1216-505-0679" className="icon me-3">
                  <i className="bi bi-telephone"></i>
                </a>

                <a href="tel:+1216-505-0679" className="cont">
                  <small className="text-muted m-0">Contact</small>
                  <h6>+1 216-505-0679</h6>
                </a>
              </div>
              <div className="qoute-nav ps-4">
                <Link
                  to="/pricing-plan"
                  className="btn sm-butn butn-gard border-0 text-white"
                >
                  <span>Free Quote</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}
