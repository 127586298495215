/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import contact_a from "../../../assets/img/icons/contact_a.png";
import contact_message from "../../../assets/img/icons/contact_message.png";
import { SubmitHandler, useForm } from "react-hook-form";
import { AppConfig } from "../../../assets/config";
import { toast } from "react-toastify";

type contactInputs = {
  first_name: string;
  email: string;
  phone: number;
  company_name: string;
  subject: string;
  message: string;
};

const ContactSection2 = () => {
  const params = {
    org_code: AppConfig.org_code,
    project_id: AppConfig.project_id,
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<contactInputs>();

  const onSubmit: SubmitHandler<contactInputs> = async (data) => {
    const name = data.first_name.split(' ')
    const messageData = {
      ...data,
      first_name: name.length >= 1 ? name[0].trim() : '',
      last_name: name.length > 1 ? name[1].trim() : '',
    }

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": `${AppConfig.apiKey}`,
      },
      body: JSON.stringify({ contact_message: messageData, ...params }),
    };
    try {
      await fetch(`${AppConfig.url}/bigya/v1.0/contact_messages`, options)
        .then((response) => {
          if (!response.ok) {
            throw response.json;
          }
          toast.success("Message Sent!");
          reset()
        })
        .catch((err) => {
          toast.error(`${err}`);
        });
    } catch (err) {
      toast.error(`${err}`);
    }
  };

  return (
    <React.Fragment>
      {/* <!-- ====== start contact page ====== --> */}
      <section className="contact section-padding pt-50 style-6">
        <div className="container">
          <div className="section-head text-center mb-100 style-5">
            <h2 className="mb-20">
              Get In <span> Touch </span>
            </h2>
            <p>We will contact as soon as your message is received.</p>
          </div>
          <div className="text-center mb-100">
            <a href="tel:216-505-0679">
              <h2 className="ltspc-20 text-uppercase fs-1 lh-1 mb-50 mt-30 color-blue5">
                216-505-0679
              </h2>
            </a>

            <h4 className="fw-normal mb-20 color-000">
              <a href="mailto:contact@nebham.com?subject=Contact through Website">
                contact@nebham.com
              </a>
            </h4>
            <h4 className="fw-normal mb-10 color-000">Cleveland, OH</h4>
          </div>
          <div className="content">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <form
                  className="form"
                  action="#"
                  onSubmit={handleSubmit(onSubmit)}
                  method="POST"
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mb-20">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            {...register("first_name", {required: true})}
                        />
                        {errors.first_name && (
                            <p className=" mt-2">{errors.first_name?.message}</p>
                        )}
                      </div>
                      {errors.first_name && (
                          <p className="text-danger mx-4 mt-2">
                            **This field is required
                          </p>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-20">
                        <input
                            type="email"
                            className="form-control"
                            placeholder="Email Address *"
                            {...register("email", {required: true})}
                        />
                      </div>
                      {errors.email && (
                          <p className="text-danger mx-4 mt-2">
                            **This field is required
                          </p>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-20">
                        <input
                            type="phone"
                            className="form-control"
                            placeholder="Phone Number (optional)"
                            {...register("phone")}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-20">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Company (optional)"
                            {...register("company_name")}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-20">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Subject"
                            {...register("subject")}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <textarea
                            rows={5}
                            className="form-control"
                            placeholder="Message"
                            {...register("message", {required: true})}
                        ></textarea>
                        {errors.message && (
                            <p className="text-danger mx-4 mt-2">
                              **This field is required
                            </p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12 text-center mt-4">
                      <input
                          type="submit"
                          value="Send Your Request"
                          className="btn rounded-pill blue5-3Dbutn hover-blue2 sm-butn fw-bold text-light"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <img src={contact_a} alt="" className="contact_a"/>
            <img src={contact_message} alt="" className="contact_message"/>
          </div>
        </div>
      </section>
      {/* <!-- ====== end contact page ====== --> */}
    </React.Fragment>
  );
};

export default ContactSection2;
